
/**
 * Bootstrap Fonts
 */

@import url(https://fonts.googleapis.com/css?family=Abel|Satisfy);
@import url(https://fonts.googleapis.com/css?family=Oswald:400,300);
@import url(https://fonts.googleapis.com/css?family=Poppins);
@import url(https://fonts.googleapis.com/css?family=Assistant);

/*@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.eot');
    src: url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.woff') format('woff'),
    url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.ttf') format('truetype'),
    url('../bower_components/bootstrap/fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

/**
 *Font Awesome Fonts
 */

/*@font-face {
    font-family: 'FontAwesome';
    src: url('../bower_components/font-awesome/fonts/fontawesome-webfont.eot?v=4.1.0');
    src: url('../bower_components/font-awesome/fonts/fontawesome-webfont.eot?#iefix&v=4.1.0') format('embedded-opentype'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.woff?v=4.1.0') format('woff'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.ttf?v=4.1.0') format('truetype'),
    url('../bower_components/font-awesome/fonts/fontawesome-webfont.svg?v=4.1.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

/**
 * App-wide Styles
 */

.browsehappy {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}