
body {
padding-top: 60px;
/*background-color: #0C0A03;*/
/*background-color: #13120B;*/
/*background-color: #070806;*/
/*background-color: #0e0d06;
background-color:#0e0a07;
background-color:#171b21; */
background-color: #171b21; 


color: #ffffff;
/*font-family: "Lucida Grande","Lucida Sans Unicode","Trebuchet MS",Helvetica,Arial,Verdana,sans-serif;*/
/*font-family: 'Abel', sans-serif;*/
/*font-family: Georgia, serif;*/
font-family: 'Poppins', sans-serif;
}

h1 {
	font-family: 'Poppins', sans-serif;
}

a {
	color: #A3CEF1;
	text-decoration: none;
}

li a {
	color: #ffffff;
	text-decoration: none;
}

table a {
	color: #BCE7FD;
	text-decoration: none;
}

.jumbotron {
	margin-top: 20px;
}

.container {
	/*background-color: #123123;*/
	/*background-image:url('genocide/genocide1.jpg');*/
}

.frontImage {
	position:relative;
	margin: 6% 18%;
}

.frontImage img {
	opacity:1;
	-moz-transition: opacity 2s; /* Firefox 4 */
	-webkit-transition: opacity 2s; /* Safari and Chrome */
	-o-transition: opacity 2s;
	transition: opacity 2s;
}

.content img {
	opacity:1;
	-moz-transition: opacity 2s; /* Firefox 4 */
	-webkit-transition: opacity 2s; /* Safari and Chrome */
	-o-transition: opacity 2s;
	transition: opacity 2s;
}

.music img {
	opacity:1;
	-moz-transition: opacity 2s; /* Firefox 4 */
	-webkit-transition: opacity 2s; /* Safari and Chrome */
	-o-transition: opacity 2s;
	transition: opacity 2s;
}

.release img {
	opacity:1;
	-moz-transition: opacity 2s; /* Firefox 4 */
	-webkit-transition: opacity 2s; /* Safari and Chrome */
	-o-transition: opacity 2s;
	transition: opacity 2s;
}

.navbar {
	background-color: #171b21;
	min-height: 60px;
	border-bottom : 1px solid white;
}

/* menu */
.navbar-nav > li > a {
	margin-right: 0.12em;
	padding: 1.5em;
	text-decoration: none;
	text-transform: none;
	font-family: 'Assistant', sans-serif;
	font-size: 1.2em;
	/* color: #2C2C2C; */
	background-color: #171b21;
	color: #fff;
}

/* title */
.navbar-brand {
	float: left;
	padding: 0.7em 0.25em 0em 0em;
	text-decoration: none;
	text-transform: none;
	font-family: 'Oswald', sans-serif;
	font-size: 2.5em;
	font-weight: 100;
	color: #fff;
	max-width: 300px;
}

.navbar-nav > .active > a > a:hover, .navbar-nav > .active > a:focus {
	color: #72b6ec;
	background-color: #171b21;
}

.navbar-nav > .active > a:focus {
	color: #72b6ec;
	background-color: #171b21;
}

.navbar-nav > .active > a, .navbar-nav > .active > a:hover, .navbar-nav > .active > a:focus {
	color: #72b6ec;
	background-color: #171b21;
}

.navbar-nav > .active > a:hover  {
	/*background: #212121;*/
	background: #171b21;
	color: #72b6ec;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus
{
	color: #ffffff;
	background-color: #2f5172;
}

.nav-pills
{
	margin-bottom: -1%;
}

.navbar-toggle {
top: 14px;
/* left:1px; */
right: 55px;
}

.music {
	margin: 5% 5%;
}

.music h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: 'Poppins', sans-serif;
    font-weight: 100;
    line-height: 1.5;
}

.music img {
	border: 1px;
	border-style: solid;
	border-color: #333333;
}

.img-center img {
	display: block;
	margin-top: 2%;
    margin-left: auto;
    margin-right: auto;
}

.img-center-noborder img {
	border: 0px !important;
	border-style: none;
	display: block;
	margin-top: 0%;
    margin-left: auto;
    margin-right: auto;
}

.bandcamp {
	display: block;
	margin-top: 2%;
	margin-bottom: 2%;
    margin-left: 10%;
    margin-right: auto;
}

.bandcamp .iframe {
	border: 0; width: 100%; height: 1010px;
}

.content {
	margin:4% auto;
	max-width: 80%;
	text-align: justify;
}

.performance {
	color: #CCD6EB;
	text-decoration: none;
}

.content row {
	margin:0 auto;
}

.content article {
	margin-bottom: 6%;
}

.content .release {
	margin-top: 2%;
}

.content .release-title {
	font-size: 1.5em;
}

.content .release-info {
	float: right;
	margin-top: -10px;
}

.content h1 {
	font-size: 1.25em;
	margin-bottom: 3%;
}

.content strong {
	color: #c42e6b;
	font-weight: bold;
}

.content .date {
	font-size: 1.5em;
	margin-bottom: -1%;
}

.content img {
	border: 1px;
	border-style: solid;
	border-color: #cccccc;
	margin-bottom: 2%;
}

.content p {
	line-height: 25px;
	font-size: 14px;
	color: #FFFFFF;
	text-align: justify;
}

.content .quote {
	display: block;
	font-style: italic;
	padding-bottom: 3%;
	text-align: center;
}

.content .media {
	padding-top: 2%;
}

.bio { padding-top: 1%; padding-left : 1%}
.biolinks { padding-left : 10%}
.biolinks a { color: #dfdfdf; }

.soundcloud {
	margin-top: -5%;
	margin-left: auto;
    margin-right: auto;
}

.thesis {
	padding-top: 10%;
	margin-left: 20%;
    margin-right: auto;
}

.thesis a {
	font-size: 2em;
}

.main {
	margin-top: -2%;
}

.main img {
	margin-top: 5%;
}

@media screen and (max-width: 767px) {

	.frontImage {
	position:relative;
	margin: 5% 15%;
	top: 50px;
	}

	.content .date { font-size: 16px;}

	.content h1 { font-size: 14px;}

	.content p { font-size: 12px; }

	.navbar-brand { font-size: 2em; }

	/* .music-submenu { display: none; } */

	.release-info { display: none; }

	.bandcamp { margin-left: 0%; }
}

@media screen and (min-width: 768px) and (max-width: 1024px)  {

	.frontImage {
		position:relative;
		margin: 10% 15%;
	}

	.navbar-brand {
		font-size: 2em;
		margin-top: 6px;
		margin-left: 4px;
	}

	.bandcamp { margin-left: 0%; }
}
/* 
#phonemenu { display: none;}	 */
